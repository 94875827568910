<template>
  <div class="Voucherlist">
    <div class="top-title">
      <div class="top-text">凭证列表</div>
      
        <el-form ref="form" label-width="100px">
          <div class="top-box">
         <el-form-item label="公司名称" >
                  <el-input v-model="seeks.a"></el-input>
          </el-form-item>

              <el-button
                type="primary"
                class="biy-icon" icon="el-icon-check" @click="seek()"
                >搜索</el-button>
            </div>
        </el-form>
      
    </div>

    <div class="table-tap">
       <ul>
          <li v-for="(item,i) in taplist" :key="i"> <span @click="tap(item)" :class="classactive == item.value?'active':''">{{item.label}}</span></li>
        </ul>
    </div>
    <!-- 选择列表  表格-->
    <div class="table-form">
      <el-table :data="list" border  v-loading="loading">
        <el-table-column prop="company_name" label="公司名称"> </el-table-column>
        <el-table-column prop="money" label="金额"> </el-table-column>
         <el-table-column label="凭证图片"> 
            <template slot-scope="scope">
                      <el-image 
                          @click="scopes(scope.row)"
                          style="width: 200px; height: 100px"
                          :src="upLoadUrl+scope.row.offline_proof">
                        </el-image>
            </template>
        </el-table-column>
        <el-table-column :prop="classactive == 3?'occurdatetime':'offline_remark'" label="上传时间"> </el-table-column>
        <el-table-column v-if="classactive == 3 ?true:false" prop="offline_remark" label="拒绝理由"> </el-table-column>

        <el-table-column label="操作" width="300" class="table-form-btn" v-if="baseGetSeparate == 1 ?classactive == 2 ?true:false:classactive == 3 ?true:false" >
          <template slot-scope="scope">
            <el-button
              @click="queren(scope.row)"  class="table-form-btn"  type="text"
               v-if="baseGetSeparate == 1 ?true:false"
              >商家已确认打款</el-button>
            <el-button
              @click="jujue(scope.row)"  class="table-form-btn"  type="text"
               v-if="baseGetSeparate == 1 ?true:false"
              >拒绝</el-button>
            <el-button
              @click="xiugai(scope.row)"  class="table-form-btn"  type="text"
              v-if="baseGetSeparate == 2 ?true:false" >修改</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <div class="block" style="margin-top: 15px">
        <el-pagination
          align="center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>

<!-- 拒绝理由 -->
  <el-dialog title="拒绝理由" :visible.sync="dialogFormVisible" width="600px">
      <el-form :model="listpar" label-width="150px">
           <el-form-item label="请输入拒绝理由：">
            <el-input type="textarea" rows="2"  v-model="listpar.jujue"></el-input>
          </el-form-item>
      </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false">取 消</el-button>
    <el-button type="primary" @click="jujuexiugai()">确 定</el-button>
  </div>
</el-dialog>
<!-- 放大图片 -->
  <el-dialog title="凭证" :visible.sync="dialogFormVisibleimg" width="800px;text-align: center;">
    <div class="amplifier">
          <img :src="upLoadUrl+amplifier" alt="">
    </div>
      
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisibleimg = false">确定</el-button>
  </div>
</el-dialog>
  </div>
</template>
<script>
// 假数据
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"


export default {
  data() {
    return {
            // 上传图片的地址
      upLoadUrl:config.ImgUrl,
      // 分页
      currentPage: 1, // 当前页码 
      total: 10,
      pageSize: 10, // 每页的数据条数
      loading: this.$common.loading,

      dialogFormVisible:false,
      // 放大器
      dialogFormVisibleimg:false,
      amplifier:'',

      classactive:2,
       taplist:fakelist.voucherlist.one,
      // 下拉框
      seeks: {},
      // 判断商家端和会员端
      baseGetSeparate:this.$common.baseGetSeparate(),

// 图片放大
       srcList: [
         
        ],
      // 假数据
      list:[],
      listpar:{}
    };
  },
  created() {
   
    this.getSong()
  },
  mounted() {},

  methods: {
    scopes(row){
      this.amplifier = row.offline_proof
      this.dialogFormVisibleimg = true
    },
    //查询接口
      async getSong(){
				let res = await apiurl.fetchPageData({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        pageIndex: this.currentPage,
        pageSize: this.pageSize,

        bizCatalog:'AccountEntity',
        handleMode:'fetchallOfflinePayin',
        query:{
            status:this.classactive,
            companyName:this.seeks.a
        }
        })
         
        if(this.$common.verifyAjaxResult(res,false)==false) return;
           this.list = res.Result.pageData
           this.total = res.Result.count
           this.loading = false
      },
  // 选项卡
      tap(e){
      this.currentPage = 1;
       this.classactive = e.value
       this.getSong();
      },

      //拒绝弹窗
    jujue(row){
   
      this.listpar = row
      this.dialogFormVisible = true
    },

      //拒绝理由修改
    async  jujuexiugai(){
    	let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家

        bizCatalog:'AccountEntity',
        handleMode:'offlinePayinAudit',
        todo:'refuse',
        bizId:this.listpar.id,
        reason:this.listpar.jujue,
       })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
         this.dialogFormVisible = false
         this.getSong();
         this.$alert('成功拒绝', '消息提示', {confirmButtonText: '确定'});
      },


      //确认弹窗
       queren(row){
         this.listpar = row
          this.$confirm('是否确认已打款', '消息提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消操作'
        }).then(() => {
          this.querens()
          }).catch(action => {
            console.log("取消");
          })
      },

      //确认
    async  querens(){
    	let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家

        bizCatalog:'AccountEntity',
        handleMode:'offlinePayinAudit',
        todo:'allow',
        bizId:this.listpar.id,
       })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
         this.dialogFormVisible = false
         this.getSong();
         this.$alert('确认成功', '消息提示', {confirmButtonText: '确定'});
      },



    // 跳转详情页
    xiugai(row) {
          this.$router.push({
          path: '/home/Upvoucher',
          query: {
            id: row.id,
            money:row.money,
            urlImage:row.offline_proof
          }
        })
    },

    // 搜索栏
    seek(){
      this.currentPage = 1;
       this.getSong();
    },


    // 分页 每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.pageSize = val;
      this.getSong();
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSong();
    },
  },
  computed: {},
};
</script>


<style  scoped>
 img{
   cursor: pointer;
 }
 .amplifier{
   width: 500px;
   margin: 0 auto;
   
 }
 .amplifier img{
   width: 500px;
 }
.table-tap{
  width: 98%;
  margin: 0 auto;
  padding: 30px 0;
  border-bottom: 2px solid rgb(255, 187, 0);
}
.table-tap ul{
  display: flex;
  justify-content: left;
}
.table-tap ul li{
  width: 150px;
  text-align: center;
}
.table-tap ul li span{
font-size: 18px;
cursor: pointer;
transition: all .3s;
}
.table-tap ul li span.active{
  color: rgb(255, 187, 0);
  font-size: 16px;
}
@import "../../assets/css/liststyle.css";
</style>